import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const createAccount = createAsyncThunk(
	'signUp/createAccount',
	async (account) => {
		try {
			const response = await window.fetch('/api/account/signup', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(account),
			});
			await response.status;
			if (response.ok) {
				return response.json;
			}
			throw new Error(response.status);
		} catch (err) {
			return Promise.reject(err.message);
		}
	}
);

const initialState = {
	accountCreated: false,
	accountCreatInProgress: false,
	accountCreationErrorStatus: null,
};

export const signUpSlice = createSlice({
	name: 'signUp',
	initialState,
	reducers: {
		resetAccountCreationErrorStatus: (state) => {
			state.accountCreationErrorStatus = null;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(createAccount.pending, (state) => {
				state.accountCreatInProgress = true;
			})
			.addCase(createAccount.fulfilled, (state) => {
				state.accountCreated = true;
				state.accountCreatInProgress = false;
			})
			.addCase(createAccount.rejected, (state, payload) => {
				state.accountCreationErrorStatus = payload.error.message;
				state.accountCreatInProgress = false;
			});
	},
});

export const { resetAccountCreationErrorStatus } = signUpSlice.actions;

export default signUpSlice.reducer;
