import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import SvgIcon from '@mui/material/SvgIcon';

import Link from '@mui/joy/Link';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import Tooltip from '@mui/joy/Tooltip';

import SWModal from './SWModal';
import { setOpen } from './swModalSlice';

import { ReactComponent as GoogleIcon } from '../google-color-icon.svg';
import { ReactComponent as MicrosoftIcon } from '../microsoft-icon.svg';
import speakWiseFullImg from '../speakwise-full-logo.png';

import { TERMS_AND_CONTIDIONS } from '../constants';

function SingIn() {
	const [errorMessage, setErrorMessage] = useState('');
	const dispatch = useDispatch();

	let error = false;
	const queryParams = window.location.search;
	if (queryParams && queryParams.includes('error')) {
		error = true;
	}

	useEffect(() => {
		if (error) {
			setErrorMessage('Invalid username or password!');
		}
	}, [error]);

	const openTermsAndConditions = () => {
		dispatch(setOpen(true));
	};

	return (
		<div>
			<SWModal content={TERMS_AND_CONTIDIONS} />
			<Stack
				direction="column"
				justifyContent="flex-start"
				alignItems="start"
				padding="40px"
				spacing={1}
				minWidth="400px"
				minHeight="600px"
				boxShadow="0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
				borderRadius="5px"
			>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					spacing={2}
					width="100%"
				>
					<Stack>
						<Typography variant="h5" fontWeight={550}>
							Sign in
						</Typography>
						<Typography variant="body2" color="#949ba3">
							New to SpeakWise ?{' '}
							<Link
								href="/signup"
								fontWeight={600}
								style={{ color: '#33819c', textDecoration: 'none' }}
							>
								Sign up!
							</Link>
						</Typography>
					</Stack>
					<img
						src={speakWiseFullImg}
						style={{ maxWidth: '100px' }}
						alt="speakwise.io"
					/>
				</Stack>
				<Stack width="100%">
					<form action="/login" method="post">
						<FormControl
							required
							style={{ marginTop: '15px', marginBottom: '5px' }}
						>
							<FormLabel>Email</FormLabel>
							<Input type="email" name="username" />
						</FormControl>
						<FormControl required>
							<FormLabel>Password</FormLabel>
							<Input type="password" name="password" />
						</FormControl>
						<Stack
							width="100%"
							alignItems="end"
							style={{ marginTop: '5px', marginBottom: '20px' }}
						>
							<Tooltip title="Temporary unavailable">
								<span>
									<Link
										href=""
										style={{ color: '#33819c', textDecoration: 'none' }}
										disabled
									>
										Forgot your password?
									</Link>
								</span>
							</Tooltip>
						</Stack>
						<Button
							style={{ backgroundColor: '#33819c' }}
							type="submit"
							fullWidth
						>
							Sign in
						</Button>
						<FormLabel
							style={{
								color: 'red',
								marginTop: '15px',
								marginLeft: '5px',
								minHeight: '20px',
							}}
						>
							{errorMessage}
						</FormLabel>
					</form>
				</Stack>
				<Divider
					style={{ marginTop: '25px', marginBottom: '20px' }}
					sx={(theme) => ({
						[theme.getColorSchemeSelector('light')]: {
							color: { xs: '#FFF', md: 'text.tertiary' },
						},
					})}
				>
					or
				</Divider>
				<Link
					href="/oauth2/authorize/google"
					width="100%"
					style={{ textDecoration: 'none' }}
				>
					<Button
						variant="soft"
						color="neutral"
						fullWidth
						startDecorator={
							<SvgIcon size="small">
								<GoogleIcon />
							</SvgIcon>
						}
					>
						&nbsp;Sign in with Google
					</Button>
				</Link>
				<Link
					href="/oauth2/authorize/azure-dev"
					width="100%"
					style={{ textDecoration: 'none' }}
				>
					<Button
						variant="soft"
						color="neutral"
						fullWidth
						startDecorator={
							<SvgIcon size="small">
								<MicrosoftIcon />
							</SvgIcon>
						}
					>
						&nbsp;Sign in with Microsoft
					</Button>
				</Link>
				<Tooltip
					title="Comming soon!"
					placement="bottom"
					size="sm"
					variant="soft"
				>
					<span style={{ width: '100%' }}>
						<Button disabled variant="soft" color="neutral" fullWidth>
							&nbsp;Sign in with Okta
						</Button>
					</span>
				</Tooltip>
				<Stack style={{ marginTop: '20px' }}>
					<Typography
						variant="subtitle2"
						fontSize="10px"
						color="#949ba3"
						maxWidth="400px"
					>
						By clicking Sign In, you agree to our{' '}
						<Link
							onClick={openTermsAndConditions}
							tabIndex={0}
							component="button"
							style={{
								color: '#33819c',
								textDecoration: 'none',
								fontSize: '10px',
							}}
						>
							terms and conditions
						</Link>
						. Learn how we collect, use and share your data in our Privacy
						Policy and how we use cookies and similar technology in our Cookies
						Policy.
					</Typography>
				</Stack>
			</Stack>
		</div>
	);
}

export default SingIn;
