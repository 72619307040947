import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Link from '@mui/joy/Link';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import Checkbox from '@mui/joy/Checkbox';
import FormHelperText from '@mui/joy/FormHelperText';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import validator from 'validator';

import SWModal from './SWModal';
import { setOpen } from './swModalSlice';
import { createAccount, resetAccountCreationErrorStatus } from './signUpSlice';

import speakWiseImgOnlyIcon from '../speakwise-only-icon-250x250.png';
import speakWiseFullImg from '../speakwise-full-logo.png';

import { TERMS_AND_CONTIDIONS, USER_FILEDS } from '../constants';

function SingUp() {
	const accountCreated = useSelector((state) => state.signUp.accountCreated);
	const accountCreationErrorStatus = useSelector(
		(state) => state.signUp.accountCreationErrorStatus
	);
	const accountCreatInProgress = useSelector(
		(state) => state.signUp.accountCreatInProgress
	);

	const [firstName, setFirstName] = useState(null);
	const [lastName, setLastName] = useState(null);
	const [company, setCompany] = useState(null);
	const [email, setEmail] = useState(null);
	const [password, setPassword] = useState(null);
	const [confirmPassword, setConfirmPassword] = useState(null);
	const [termsAndConditions, setTermsAndConditions] = useState(false);
	const [firstNameError, setFirstNameError] = useState(false);
	const [lastNameError, setLastNameError] = useState(false);
	const [emailError, setEmailError] = useState(false);
	const [emailErrorMsg, setEmailErrorMsg] = useState('');
	const [passwordError, setPasswordError] = useState(false);
	const [passwordErrorMsg, setPasswordErrorMsg] = useState('');

	const dispatch = useDispatch();

	const queryParams = window.location.search;
	useEffect(() => {
		if (queryParams?.includes('conflictError')) {
			setEmailErrorMsg(
				'This email is already associated with another account!'
			);
			setEmailError(true);
		}
	}, [queryParams]);

	useEffect(() => {
		if (accountCreationErrorStatus === '409') {
			setEmailErrorMsg(
				'This email is already associated with another account!'
			);
			setEmailError(true);
		}
	}, [accountCreationErrorStatus]);

	const handleInputChange = (event, category) => {
		const value = event.target.value || ' ';
		switch (category) {
			case USER_FILEDS.FIRST_NAME:
				setFirstName(value);
				break;
			case USER_FILEDS.LAST_NAME:
				setLastName(value);
				break;
			case USER_FILEDS.COMPANY:
				setCompany(value);
				break;
			case USER_FILEDS.EMAIL:
				setEmail(value);
				break;
			case USER_FILEDS.PASSWORD:
				setPassword(value);
				break;
			case USER_FILEDS.CONFIRM_PASSWORD:
				setConfirmPassword(value);
				break;
			default:
		}
	};

	const createAccountAction = () => {
		let hasError = false;
		dispatch(resetAccountCreationErrorStatus());

		if (!firstName || firstName.trim().length === 0) {
			setFirstNameError(true);
			hasError = true;
		} else {
			setFirstNameError(false);
		}

		if (!lastName || lastName.trim().length === 0) {
			setLastNameError(true);
			hasError = true;
		} else {
			setLastNameError(false);
		}

		if (!email || email.trim().length === 0) {
			setEmailError(true);
			hasError = true;
		} else if (!validator.isEmail(email)) {
			setEmailError(true);
			setEmailErrorMsg('Email adress is not valid!');
			hasError = true;
		} else {
			setEmailError(false);
			setEmailErrorMsg('');
		}
		if (
			!password ||
			!confirmPassword ||
			password.trim().length === 0 ||
			confirmPassword.trim().length === 0
		) {
			setPasswordError(true);
			hasError = true;
		} else if (password.length < 10) {
			setPasswordError(true);
			setPasswordErrorMsg('Password should have at least 10 characters!');
			hasError = true;
		} else if (password !== confirmPassword) {
			setPasswordError(true);
			// eslint-disable-next-line quotes
			setPasswordErrorMsg("Passwords dosen't match!");
			hasError = true;
		} else {
			setPasswordError(false);
			setPasswordErrorMsg('');
		}
		if (hasError) {
			return;
		}

		dispatch(createAccount({ firstName, lastName, company, email, password }));
	};

	const handleTermsAndConditions = () => {
		setTermsAndConditions(!termsAndConditions);
	};
	const openTermsAndConditions = () => {
		dispatch(setOpen(true));
	};
	const accountCreatedView = () => (
		<Stack
			justifyContent="center"
			alignItems="center"
			width="100%"
			direction="column"
			spacing={1}
		>
			<img
				src={speakWiseImgOnlyIcon}
				style={{ maxWidth: '100px' }}
				alt="speakwise.io"
			/>
			<Typography variant="h6">Account was created.</Typography>
		</Stack>
	);

	const signUpForm = () => (
		<Stack width="100%" direction="column" spacing={1}>
			<Stack width="100%" direction="row" spacing={2}>
				<FormControl required>
					<FormLabel>First Name</FormLabel>
					<Input
						type="text"
						name={USER_FILEDS.FIRST_NAME}
						onChange={(e) => handleInputChange(e, USER_FILEDS.FIRST_NAME)}
						error={firstNameError}
					/>
				</FormControl>
				<FormControl required>
					<FormLabel>Last Name</FormLabel>
					<Input
						type="text"
						name={USER_FILEDS.LAST_NAME}
						onChange={(e) => handleInputChange(e, USER_FILEDS.LAST_NAME)}
						error={lastNameError}
					/>
				</FormControl>
			</Stack>
			<Stack
				width="100%"
				direction="column"
				spacing={1}
				style={{ marginTop: '10px' }}
			>
				<FormControl>
					<FormLabel>Company</FormLabel>
					<Input
						type="text"
						name={USER_FILEDS.COMPANY}
						onChange={(e) => handleInputChange(e, USER_FILEDS.COMPANY)}
					/>
				</FormControl>
				<FormControl required>
					<FormLabel>Email</FormLabel>
					<Input
						type="email"
						name={USER_FILEDS.EMAIL}
						onChange={(e) => handleInputChange(e, USER_FILEDS.EMAIL)}
						error={emailError}
					/>
					<FormHelperText style={{ color: 'red' }}>
						{emailErrorMsg && <ErrorOutlineIcon />}
						{emailErrorMsg}
					</FormHelperText>
				</FormControl>
				<FormControl required>
					<FormLabel>Password</FormLabel>
					<Input
						type="password"
						name={USER_FILEDS.PASSWORD}
						onChange={(e) => handleInputChange(e, USER_FILEDS.PASSWORD)}
						error={passwordError}
					/>
				</FormControl>
				<FormControl required color="neutral">
					<FormLabel>Confirm Password</FormLabel>
					<Input
						type="password"
						name={USER_FILEDS.CONFIRM_PASSWORD}
						onChange={(e) => handleInputChange(e, USER_FILEDS.CONFIRM_PASSWORD)}
						error={passwordError}
					/>
					<FormHelperText style={{ color: 'red' }}>
						{passwordErrorMsg && <ErrorOutlineIcon />}
						{passwordErrorMsg}
					</FormHelperText>
				</FormControl>
			</Stack>
			<Stack
				width="100%"
				alignItems="start"
				style={{ marginTop: '15px', marginBottom: '20px' }}
			>
				<FormControl sx={{ width: '100%' }}>
					<Checkbox
						required
						onChange={handleTermsAndConditions}
						checked={termsAndConditions}
						label=" I have read and agree to the terms and conditions"
						color="neutral"
					/>
					<FormHelperText>
						<Typography variant="body2" color="#949ba3">
							Read our{' '}
							<Link
								onClick={openTermsAndConditions}
								tabIndex={0}
								component="button"
								style={{ color: '#33819c', textDecoration: 'none' }}
							>
								terms and conditions
							</Link>
							.
						</Typography>
					</FormHelperText>
				</FormControl>
			</Stack>
			<Button
				loading={accountCreatInProgress}
				color="neutral"
				disabled={!termsAndConditions}
				onClick={createAccountAction}
				style={{
					backgroundColor: '#33819c',
					marginBottom: '10px',
					marginTop: '20px',
				}}
				fullWidth
			>
				Create Account
			</Button>
		</Stack>
	);

	return (
		<div>
			<SWModal content={TERMS_AND_CONTIDIONS} />
			<Stack
				direction="column"
				justifyContent="flex-start"
				alignItems="start"
				padding="40px"
				spacing={1}
				minWidth="430px"
				boxShadow="0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
				borderRadius="5px"
			>
				{!accountCreated && (
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						spacing={2}
						width="100%"
					>
						<Typography variant="h5" fontWeight={550}>
							Sign up
						</Typography>
						<img
							src={speakWiseFullImg}
							style={{ maxWidth: '100px' }}
							alt="speakwise.io"
						/>
					</Stack>
				)}
				{!accountCreated && signUpForm()}
				{accountCreated && accountCreatedView()}
				<Stack width="100%" alignItems="center">
					<Link
						href="/login"
						style={{ color: '#33819c', textDecoration: 'none' }}
					>
						Back to login
					</Link>
				</Stack>
			</Stack>
		</div>
	);
}

export default SingUp;
