import { configureStore } from '@reduxjs/toolkit';
import swModalReducer from './components/swModalSlice';
import signUpReducer from './components/signUpSlice';

export default configureStore({
	reducer: {
		swModal: swModalReducer,
		signUp: signUpReducer,
	},
});
