import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	open: false,
};

export const swModalSlice = createSlice({
	name: 'swModal',
	initialState,
	reducers: {
		setOpen: (state, action) => {
			state.open = action.payload;
		},
	},
});

export const { setOpen } = swModalSlice.actions;

export default swModalSlice.reducer;
