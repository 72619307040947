import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Sheet from '@mui/joy/Sheet';

import { setOpen } from './swModalSlice';

import speakWiseImgOnlyIcon from '../speakwise-only-icon-250x250.png';

function SWModal({ content }) {
	const open = useSelector((state) => state.swModal.open);
	const dispatch = useDispatch();

	const onClose = () => {
		dispatch(setOpen(false));
	};

	return (
		<Modal
			aria-labelledby="SpeakWise"
			aria-describedby="SpeakWise"
			open={open}
			onClose={onClose}
			sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
		>
			<Sheet
				variant="outlined"
				sx={{
					maxWidth: 500,
					borderRadius: 'md',
					p: 3,
					boxShadow: 'lg',
				}}
			>
				<ModalClose variant="plain" sx={{ m: 1 }} />
				<Stack
					direction="column"
					justifyContent="center"
					alignItems="center"
					spacing={5}
					width="100%"
				>
					<img
						src={speakWiseImgOnlyIcon}
						style={{ maxWidth: '100px' }}
						alt="speakwise.io"
					/>
					<Typography variant="body2" maxHeight="500px" overflow="auto">
						{content}
					</Typography>
				</Stack>
			</Sheet>
		</Modal>
	);
}
export default SWModal;
