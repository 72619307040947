import React, { Suspense } from 'react';
import {
	BrowserRouter as Router,
	Navigate,
	Route,
	Routes,
} from 'react-router-dom';
import { Provider } from 'react-redux';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import SingIn from './components/SignIn';
import SingUp from './components/SignUp';
import store from './store';

function App() {
	return (
		<div>
			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				marginTop="100px"
			>
				<Provider store={store}>
					<Router>
						<Suspense fallback="loading...">
							<Routes>
								<Route path="login/*" element={<SingIn />} />
								<Route path="signup/*" element={<SingUp />} />
								<Route path="*" element={<Navigate to="/login" replace />} />
							</Routes>
						</Suspense>
					</Router>
				</Provider>
			</Box>
			<AppBar
				position="fixed"
				sx={{
					top: 'auto',
					bottom: 0,
					backgroundColor: 'white',
					boxShadow: 'none',
					minHeight: '35px',
				}}
			>
				<Typography
					variant="subtitle2"
					style={{ fontWeight: '600' }}
					color="#949ba3"
					textAlign="center"
				>
					© SpeakWise {new Date().getFullYear()}
				</Typography>
			</AppBar>
		</div>
	);
}

export default App;
