export const TERMS_AND_CONTIDIONS = `Speakwise Terms and Conditions
Welcome to Speakwise!
Speakwise ("we", "us", "our") is a web-based software application designed to improve public speaking and delivery of messages, speeches, or presentations through verbal, video, or in-person methods. By accessing or using Speakwise, you agree to be bound by these Terms and Conditions ("Terms"). If you do not agree with these Terms, please do not use Speakwise.
1. Acceptance of Terms
By accessing or using Speakwise, you confirm that you have read, understood, and agree to be bound by these Terms and our Privacy Policy.
2. Changes to Terms
We reserve the right to modify these Terms at any time. We will notify you of any material changes by posting the new Terms on our website and, if the changes are significant, we will provide a more prominent notice (such as an email notification). Any changes will become effective 30 days after they are posted. Your continued use of Speakwise after the changes become effective signifies your acceptance of the revised Terms. Please review these Terms regularly.
3. Account Registration
To use Speakwise, you must create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information as necessary. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account.
4. User Conduct
You agree to use Speakwise only for lawful purposes and in accordance with these Terms. You agree not to:
Use Speakwise in any way that violates any applicable federal, state, local, or international law or regulation.
Engage in any conduct that restricts or inhibits anyone's use or enjoyment of Speakwise, or which, as determined by us, may harm us or other users of Speakwise.
Use Speakwise in any manner that could disable, overburden, damage, or impair the site or interfere with any other party's use of Speakwise.
5. Data Collection and Usage
Speakwise may collect the following types of data while you use the application:
Video Data: Collected while the video camera is enabled by the user.
Audio Data: Collected while the user is actively recording a rehearsal session.
Shared Files: Collected once access is granted by the user.
User Profile Data: Information provided by the user during account registration and usage.
Usage Data: Information about how the user interacts with the application.
This data may be used to improve the application. We will not share this data with any outside parties other than our GPT technology and cloud storage technology partners, solely for the purpose of improving the functionality and performance of Speakwise.
6. Subscription and Payment
Speakwise offers both free access accounts and paid subscription services. The specific features and benefits of each type of account will be outlined on our website. By selecting a paid subscription, you agree to pay the applicable fees for the subscription plan you choose. Fees are non-refundable except as required by law.
7. Intellectual Property
All content, features, and functionality of Speakwise, including but not limited to text, graphics, logos, icons, images, audio clips, video clips, data compilations, and software, are the property of Speakwise or its licensors and are protected by United States and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.
8. Termination
We reserve the right to terminate or suspend your account and access to Speakwise, with reasonable notice where practicable, for conduct that we believe violates these Terms or is harmful to other users of Speakwise, us, or third parties.
9. Disclaimer of Warranties
Speakwise is provided "as is" and "as available," without warranties of any kind, either express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, or non-infringement. We do not warrant that Speakwise will be uninterrupted or error-free, that defects will be corrected, or that Speakwise is free of viruses or other harmful components.
10. Limitation of Liability
In no event will Speakwise, its affiliates, or their licensors, service providers, employees, agents, officers, or directors be liable for damages exceeding the amount you have paid to Speakwise in the past 12 months, under any legal theory, arising out of or in connection with your use, or inability to use, Speakwise, including any direct, indirect, incidental, special, consequential, or punitive damages, including but not limited to, personal injury, pain and suffering, emotional distress, loss of revenue, loss of profits, loss of business or anticipated savings, loss of use, loss of goodwill, loss of data, and whether caused by tort (including negligence), breach of contract, or otherwise, even if foreseeable.
11. Indemnification
You agree to defend, indemnify, and hold harmless Speakwise, its affiliates, licensors, and service providers, and their respective officers, directors, employees, agents, successors, and assigns from and against any third-party claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or relating to your violation of these Terms or your use of Speakwise, including, but not limited to, your use of Speakwise’s content, services, and products other than as expressly authorized in these Terms or your use of any information obtained from Speakwise.
12. Governing Law and Jurisdiction
All matters relating to Speakwise and these Terms, and any dispute or claimarising therefrom or related thereto (in each case, including non-contractual disputes or claims), shall be governed by and construed in accordance with the internal laws of the State of [Your State], without giving effect to any choice or conflict of law provision or rule. Any legal suit, action, or proceeding arising out of, or related to, these Terms or Speakwise shall be instituted exclusively in the federal courts of the United States or the courts of the State of [Your State].
13. Arbitration
At our sole discretion, we may require you to submit any disputes arising from these Terms or use of Speakwise, including disputes arising from or concerning their interpretation, violation, invalidity, non-performance, or termination, to final and binding arbitration under the Rules of Arbitration of the American Arbitration Association applying [Your State] law. You have the right to opt out of this arbitration clause within 30 days of accepting these Terms by contacting us at [opt-out email].
14. Waiver and Severability
No waiver by Speakwise of any term or condition set forth in these Terms shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of Speakwise to assert a right or provision under these Terms shall not constitute a waiver of such right or provision. If any provision of these Terms is held by a court or other tribunal of competent jurisdiction to be invalid, illegal, or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms will continue in full force and effect.
15. Entire Agreement
These Terms and our Privacy Policy constitute the sole and entire agreement between you and Speakwise regarding Speakwise, and supersede all prior and contemporaneous understandings, agreements, representations, and warranties, both written and oral, regarding Speakwise.
Thank you for using Speakwise!
`;

export const USER_FILEDS = {
	FIRST_NAME: 'firstName',
	LAST_NAME: 'lastName',
	EMAIL: 'email',
	COMPANY: 'company',
	PASSWORD: 'password',
	CONFIRM_PASSWORD: 'confirmPassword',
};
